import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {mergeMapTo} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpWrapperService} from './http-wrapper.service';
import {AppConstant} from './constant';
import {CommonService} from './common.service';

@Injectable()
export class MessagingService {
	private fireBaseTokenApi = AppConstant.apiUrl + AppConstant.fireBaseToken;
	private fireBaseTokenApiForAdmin = AppConstant.apiUrl + AppConstant.fireBaseTokenApiForAdmin;

	currentMessage = new BehaviorSubject<any>(null);

	constructor(
		private afMessaging: AngularFireMessaging,
		private http: HttpWrapperService,
		private common: CommonService,
	) {
		this.receiveMessage();
	}

	/**
	 * request permission for notification from firebase cloud messaging
	 *
	 * @param userId userId
	 */
	requestPermission() {
		this.afMessaging.requestPermission.pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe((token) => {
			this.saveToken(token);
		}, (error) => {
			this.saveToken(null);
			console.error(error);
			return '';
		});
	}

	requestPermissionForAdmin(adminID, JwtToken) {
		this.afMessaging.requestPermission.pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe((token) => {
			this.saveTokenForAdmin(token, adminID, JwtToken);
		}, (error) => {
			console.error(error);
			return '';
		});
	}

	receiveMessage() {
		this.afMessaging.messages.subscribe((payload) => {
			this.currentMessage.next(payload);
		});
	}

	allowNotification = () => {
		const fireBaseToken = localStorage.getItem('fireBaseToken');
		/*if (!fireBaseToken) {
			this.requestPermission();
		}*/
		this.requestPermission();
	}

	allowNotificationForAdmin = (adminID, JwtToken) => {
		const fireBaseTokenForAdmin = localStorage.getItem('fireBaseToken');
		if (!fireBaseTokenForAdmin) {
			this.requestPermissionForAdmin(adminID, JwtToken);
		} else {
			this.saveTokenForAdmin(fireBaseTokenForAdmin, adminID, JwtToken);
		}
	}

	saveToken = (token) => {
		this.saveTokenToServer({
			conultantId: this.common.getAstroData('id'),
			gcmId: token ? token : null
		}).subscribe((response: any) => {
			if (response.status == 'success') {
				localStorage.setItem('fireBaseToken', token);
				localStorage.setItem('npsLevel', response?.data?.npsLevel);
				// localStorage.setItem('rrLevel', response?.data?.rrLevel);
				localStorage.setItem('blockCeoFeedback', response?.data1?.BlockCeoFeedback);
				localStorage.setItem('blockSupportTicket', response?.data1?.BlockSupportTicket);
				localStorage.setItem('isDeleted', response?.isDeleted);
				localStorage.setItem('isOpenForSupportWithSdOff', response?.isOpenForSupportWithSdOff);
				if(response?.isDeleted && !response?.isOpenForSupportWithSdOff) {
					this.common.logout();
				}
				this.common.setFeedBackValue(response?.data1?.BlockCeoFeedback);
			} else {
				localStorage.removeItem('fireBaseToken');
				localStorage.removeItem('npsLevel');
				// localStorage.removeItem('rrLevel');
				localStorage.removeItem('blockCeoFeedback');
				localStorage.removeItem('blockSupportTicket');
				localStorage.removeItem('isDeleted');
				localStorage.removeItem('isOpenForSupportWithSdOff');
			}
		}, () => {
			localStorage.removeItem('fireBaseToken');
			localStorage.removeItem('npsLevel');
			// localStorage.removeItem('rrLevel');
			localStorage.removeItem('blockCeoFeedback');
			localStorage.removeItem('blockSupportTicket');
			localStorage.removeItem('isDeleted');
			localStorage.removeItem('isOpenForSupportWithSdOff');
		});
	}

	saveTokenForAdmin = (fireBaseToken, adminID, JwtToken) => {
		this.saveTokenToServerForAdmin({
			adminId: adminID,
			webtoken: fireBaseToken
		}, JwtToken, adminID).subscribe(() => {
		});
	}

	saveTokenToServer = (params) => {
		return this.http.postHeaderParams(this.fireBaseTokenApi, params, true);
	}

	saveTokenToServerForAdmin = (params, adminToken, adminID) => {
		return this.http.putParamRequestForAdmin(this.fireBaseTokenApiForAdmin, params, true, adminToken, adminID);
	}

	getNotification(): Observable<boolean> {
		return this.currentMessage.asObservable();
	}
}
